

.gQIqMI{
    width:3rem !important;
    height:.5rem !important;
    border: red !important;
    border-radius: 0% !important;
    background: rgba(158, 149, 149, 0.548) !important;
}

.jJKuoL {
    width:3rem !important;
    height:.5rem !important;
    border: red !important;
    border-radius: 1% !important;
    
}

/* .MuiBox-root MuiBox-root-169{
    width: 100%;
}


.MuiBox-root makeStyles-card-159{
    width: 100%;
} */



