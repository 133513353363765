.swal-modal{
  padding: 15px 0px;

}

.swal-overlay {
    background-color: rgba(30, 113, 128, 0.45);
  }
  .swal-icon--success__ring{
      border: 4px solid hsl(219deg 62% 61% / 22%);
  }
  .swal-icon--success__line{
    background-color: #182AC3;
  }
  .swal-title {
    margin: 0px;
    font-size: 1.8rem;
    margin-bottom: 28px;
    font-family: Access
  }
  .swal-text {
    font-size: 1.5rem;
    font-family: Access;
    display: block;
    text-align: center;
    color: #61534e;
  }  
  .swal-footer {
    margin-top: 0px;
    overflow: hidden;
  }
  .swal-button {
    padding: 7px 20px;
    border-radius: 2px;
    background-color: #182AC3;
    font-size: 14px;
    /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */
    transition: all .2s ease-in-out;
  }
  .swal-button:hover{
    background-color: #fff !important;
    color: #182AC3;
  }

  .swal-footer{
    text-align: center !important;
  }